import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { ApiService } from './api.service';

export interface AmountDueResponse {
  totalAmountDue: number
}

export interface PendingPaymentResponse {
  paymentIntentId: string;
  paymentType: string;
  paymentIntentStatus: string;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentMicroService extends ApiService {

  protected baseUrl: string = environment.paymentServiceBaseApiUrl;

  constructor(protected http: HttpClient) {
    super(http);
  }

  // Returns amount in cents
  getTotalAmountDue(policyId: string): Observable<AmountDueResponse> {
    return this.get<AmountDueResponse>(`/payment-service/v1/${policyId}/invoices/past-due-amount`)
  }

  getPendingPayments(policyId: string): Observable<PendingPaymentResponse[]> {
    return this.get<PendingPaymentResponse[]>(`/payment-service/v1/${policyId}/invoices/payments/pending`);
  }

  payPastDueInvoices(policyId: string): Observable<any> {
    return this.post(`/payment-service/v1/${policyId}/invoices/pay`, {});
  }
}
