import { Injectable } from '@angular/core';
import tippy from 'tippy.js';

// This is not the complete list of props from Tippy
// As we need more we can add to this interface
// https://atomiks.github.io/tippyjs/v6/all-props/
interface TippyConfig {
  content?: string;
  trigger?: string;
  theme?:
    'light' |
    'light-border' |
    'material' |
    'translucent' |
    'gray-theme';
  showOnCreate?: boolean;
  placement?:
    'top' |
    'top-start' |
    'top-end' |
    'right' |
    'right-start' |
    'right-end' |
    'bottom' |
    'bottom-start' |
    'bottom-end' |
    'left' |
    'left-start' |
    'left-end' |
    'auto' |
    'auto-start' |
    'auto-end';
    allowHTML?: boolean;
    delay?: any;
    interactive?: boolean;
}

const defaultConfig: TippyConfig = {
  theme: 'material',
};

export const PENDING_PAYMENT_MESSAGE = 'Changes to your payment method aren’t allowed while a payment is pending. You’ll be able to update it once this payment is complete.'

@Injectable({
  providedIn: 'root'
})
export class TooltipService {

  constructor() { }

  // refactor to use injected DOCUMENT
  showTooltip(elementSelector: string, tooltipString: string, config?: TippyConfig) {
    const element = document.querySelector(elementSelector);
    return tippy(
      element, {
        ...defaultConfig,
        ...config,
        content: tooltipString,
      }
    );
  }

  /**
   * Sets one or more tooltips. Will show on hover
   * @param targets - could be a css selector, element, array of elements more info: https://atomiks.github.io/tippyjs/v6/constructor/#target-types
   * @param tooltipString - content of the tooltip
   * @param config - tippy config with options on looks, tooltip placement, etc.
   * @returns
   */
   setTooltips(targets: string, tooltipString: string, config?: TippyConfig) {
    return tippy(
      targets, {
        ...defaultConfig,
        ...config,
        content: tooltipString,
      }
    );
  }
}
